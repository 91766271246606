.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: fixed;
  min-height: 60px;
  top: 0;
  width: 100%;
  background-color: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  font-size: 2em;
  color: black;
  padding: 15px;
  z-index: 1;
}

.App-link {
  color: #61dafb;
}

.App-container-wrapper {
  margin-top: 50px;
  padding: 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.date-picker {
  max-width: 7em;
}

td {
  vertical-align: middle;
}

.header-logo {
  max-height: 1em;
}

.header-text {
  position: fixed;
  right: 5%;
}

.question-title {
  font-weight: 600;
}
