.option-button {
    margin: 2px;
}

.option-button-selected {
    margin: 2px;
    background-color: blueviolet;
    color: white;
}


.float-div {
    float: lieft;
}

.float-left {
    float: lieft;
}

.float-right {
    float: right;
}

.warning-msg {
    color:red;
    font-weight: 600;
    font-size: 1.3rem;
    margin: 5px;
}

.warning-msg-small {
    color:red;
    font-weight: 500;
    font-size: 1rem;
    margin: 5px;
}

.view-and-edit-container{
    background-color: rgb(247, 245, 245);
    padding: 1em;
}

.text-count {
    float: right;
    color: gray;
}